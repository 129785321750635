<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI策划方案" />
                        <ai_select selectTitle="所属行业" :select_list="industry_list" @getSelectVal="get_industry" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">公司名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.corporate_name" placeholder="请录入公司名称"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">活动主题：</p>
                            <div class="upload_box">
                                <el-input v-model="form.activity_theme"
                                    placeholder="请录入预活动策划的具体主题。如：人工智能聊天app、音乐喷泉"></el-input>
                            </div>
                        </div>
                        <ai_button_file_data @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                industry: '',
                corporate_name: '',
                activity_theme: '',
            },
            industry_list: [
                {
                    id: '科技',
                    name: '科技'
                },
                {
                    id: '教育',
                    name: '教育'
                },
                {
                    id: '党政党建',
                    name: '党政党建'
                },
                {
                    id: '餐饮美食',
                    name: '餐饮美食'
                },
                {
                    id: '金融理财',
                    name: '金融理财'
                },
                {
                    id: '体育运动',
                    name: '体育运动'
                },
                {
                    id: '家具装修',
                    name: '家具装修'
                },
                {
                    id: '旅游旅行',
                    name: '旅游旅行'
                },
                {
                    id: '医学医疗',
                    name: '医学医疗'
                },
                {
                    id: '汽车交通',
                    name: '汽车交通'
                },
                {
                    id: '工业',
                    name: '工业'
                },
                {
                    id: '美容',
                    name: '美容'
                },
                {
                    id: '房地产',
                    name: '房地产'
                },

            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_industry(val) {
            this.form.industry = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80,
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80,
                    });
                }
            );
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/create_activities', {
                industry: this.form.industry,
                corporate_name: this.form.corporate_name,
                activity_theme: this.form.activity_theme,
                mobile: this.$user_info.mobile,
            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>